import useAnalyticsContext from '@hooks/useAnalyticsContext';
import {
	formatVendorBOW,
	formatVendorInitiatedProps,
	formatVendorUrl,
	getVendorTransactions,
	vendorInitiatedBaseProps,
} from '@redux/rfq/helpers';
import { useCallback } from 'react';
import type { InlineRfqSourceContent } from 'types/sourceContent';
import type { Decorated, Raw } from 'types/vendor';
import PageType = Page.PageType;
import { useAtomValue } from 'jotai';
import { EVENT_CONTACT_VENDOR_INITIATED } from '../../lib/vendor-recommendation-module/utils/tracking/vrmTracking';
import { isSemanticSearchCalledAtom } from '../pages/VendorsSearch/containers/SemanticSearch/atoms';

export interface useTrackContactVendorInitiatedParams {
	pageType: PageType;
	currentBadges: string[];
	sourceContent: InlineRfqSourceContent;
	additionalInitiatedTrackingProps?: object;
}

export interface trackContactVendorInitiatedParams {
	vendor: Decorated | null;
	vendorRaw: Raw | null;
}

type buildAnalyticsPropertiesParams = useTrackContactVendorInitiatedParams & {
	vendor: Decorated;
	vendorRaw: Raw;
	isSemanticSearch?: boolean;
};

const buildAnalyticsProperties = ({
	vendor,
	vendorRaw,
	pageType,
	currentBadges,
	sourceContent,
	isSemanticSearch,
}: buildAnalyticsPropertiesParams) => {
	const baseProps = vendorInitiatedBaseProps(
		vendor,
		pageType,
		isSemanticSearch,
	);
	const formattedProps = formatVendorInitiatedProps(baseProps);

	return {
		badges: currentBadges,
		sourceContent,
		vendorAddress2: vendorRaw?.location?.address?.address2,
		vendorBestOfWeddings: formatVendorBOW(vendor?.awards),
		vendorUrl: formatVendorUrl(vendor.websiteUrl, vendor.displayWebsiteUrl),
		vendorTransactions: getVendorTransactions(vendor),
		...formattedProps,
	};
};

export const useTrackContactVendorInitiated = ({
	pageType,
	currentBadges,
	sourceContent,
	additionalInitiatedTrackingProps = {},
}: useTrackContactVendorInitiatedParams) => {
	const { track } = useAnalyticsContext();
	const isSemanticSearch = useAtomValue(isSemanticSearchCalledAtom);

	return useCallback(
		({ vendor, vendorRaw }: trackContactVendorInitiatedParams) => {
			const baseProperties =
				!vendor || !vendorRaw
					? null
					: buildAnalyticsProperties({
							vendor,
							vendorRaw,
							pageType,
							currentBadges,
							sourceContent,
							isSemanticSearch,
						});

			if (!baseProperties) return;

			track({
				event: EVENT_CONTACT_VENDOR_INITIATED,
				properties: {
					...baseProperties,
					...additionalInitiatedTrackingProps,
				},
			});
		},
		[
			track,
			additionalInitiatedTrackingProps,
			currentBadges,
			pageType,
			sourceContent,
		],
	);
};
